<template>
  <div>
    <b-modal size="md" class="modal-size" hide-footer v-model="modal_table_change" title="Cambio de mesa">
        <b-form id="Form" @submit.prevent="Validate">
             <b-row>
                <b-col md="4">
                  <b-form-group label="Sala :">
                    <b-form-input disabled type="text" v-model="order.room_name"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Mesa :">
                    <b-form-input disabled type="text" v-model="order.table_name"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Mesa Nueva :">
                    <b-form-select v-model="order.id_new_table" :options="tables"></b-form-select>
                  </b-form-group>
                </b-col>

                      
             </b-row>

              <b-row  class="justify-content-md-center mt-3">
                <b-col md="5">
                  <b-button type="submit" class="form-control text-white" variant="primary" ><i class="fas fa-save"></i> Cambiar Mesa</b-button>
                </b-col>
              </b-row>
            </b-form>
    </b-modal>
    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<style>


</style>

<script>

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import LoadingComponent from './../../pages/Loading'
export default {
  name: "ModalClient",
    components:{
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      modal_table_change: false,
      module:'Letter',
      role: 0,
      id_order:'',

      order: {
        id_table :'',
        id_room :'',
        id_warehouse :'',
        id_client :'',
        room_name :'',
        table_name :'',
        id_new_table:'',
      },

      tables:[],

    };
  },
  created (){
  
  },
  mounted () {
    EventBus.$on('ModalTableChangeShow', (data) => {
      this.modal_table_change = true;
      this.role = data.role;
      this.id_order = data.id_order;
      this.ViewOrder();
    });
  },
  methods: {
    ListTableActive,
    ViewOrder,
    Validate,
    ChangeTable,
  },
  computed: {
    ...mapState('Order',["new_order_detail","order_detail"]),
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ViewOrder() {
  
  let me = this;
  let url = this.url_base + "order/view/"+me.id_order;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,module: this.module,role: me.role},
  })
    .then(function (response) {
      
      if (response.data.status == 200) {
        me.order.id_order = response.data.result.order.id_order;
        me.order.id_table = response.data.result.order.id_table;
        me.order.id_room = response.data.result.order.id_room;
        me.order.id_warehouse = response.data.result.order.id_warehouse;
        me.order.id_client = response.data.result.order.id_client;
        me.order.room_name = response.data.result.order.room_name;
        me.order.table_name = response.data.result.order.table_name;
        me.ListTableActive();
      }
    })
    .catch((error) => {
      console.log(error)
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function ListTableActive() {
  
  let me = this;
  let url = this.url_base + "table/list-tables-active-general";
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,module: this.module,role: me.role},
  })
    .then(function (response) {
      me.tables = [{value:'',text:'- Seleccione una mesa -'}];
      if (response.data.status == 200) {
        let tables = response.data.result;
        for (let index = 0; index < tables.length; index++) {
          const element = tables[index];
          if (me.order.id_table != element.id_table) {
            me.tables.push({value:element.id_table,text:element.name})
          }
        }
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function Validate() {
  let me = this;
  console.log(me.order)
  if (me.order.id_order.length == 0) {
    this.$notify({ group: 'alert', title: 'Sistema', text:'Seleccione una mesa', type: 'error'})
    return false;  
  }
  if (me.order.id_table.length == 0) {
    this.$notify({ group: 'alert', title: 'Sistema', text:'Seleccione un mesa', type: 'error'})
    return false;  
  }
  if (me.order.id_new_table.length == 0) {
    this.$notify({ group: 'alert', title: 'Sistema', text:'Seleccione una nueva mesa', type: 'error'})
    return false;  
  }

  // Swal.fire({
  //   title: 'Esta seguro de cambiar la mesa?',
  //   icon: 'warning',
  //   showCancelButton: true,
  //   confirmButtonColor: '#3085d6',
  //   cancelButtonColor: '#d33',
  //   confirmButtonText: 'Si, Estoy de Acuerdo!'
  // }).then((result) => {
  //   if (result.isConfirmed) {
  //     this.ChangeTable();
  //   }
  // })

  this.ChangeTable();
  
}

function ChangeTable() {
  let me = this;
  let url = me.url_base + "order/change-table";
  let data = {
    id_order:me.order.id_order,
    id_table:me.order.id_table,
    id_new_table:me.order.id_new_table,
  }
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token,  module: me.module, role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        EventBus.$emit('ListTable');
        me.modal_table_change = false;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}


</script>
